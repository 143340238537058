import { useVerticalScrollOverflow } from "@resource/atlas/utils/layout";
import clsx from "clsx";
import ErrorPage from "components/Generic/ErrorPage";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useQueryStringValue } from "utils/next";

import AppHeader from "./AppHeader";
import AppNav from "./AppNav";
import ErrorBoundary from "./ErrorBoundary";
import ExtensionInstallModal from "./Generic/ExtensionInstallModal";
import useModalContext, { ModalName } from "./Generic/ModalContext";

// layout context
// --------------

type LayoutContextValue = {
  restorePageFocus: () => void;
};

const LayoutContext = createContext<LayoutContextValue | null>(null);

/** Keyboard event handler that focuses the nav on escape. */
function focusNavOnEscape(e: KeyboardEvent) {
  if (e.key === "Escape") {
    // TODO: remove once ariakit fixes the escape propagation issue
    if (e.target && (e.target as HTMLElement).closest("[data-atlas-dialog]"))
      return;
    const target =
      document.querySelector(".nav-link.active") ??
      document.querySelector(".nav-link.first");
    (target as HTMLElement)?.focus();
  }
}

/** Focus the first focusable element, excluding nav links */
function focusFirstElement() {
  const firstFocusableElement = document.body.querySelector(
    ':is(button, [href], input, select, textarea, [tabindex]):not(.nav-target):not([tabindex="-1"])'
  );
  (firstFocusableElement as HTMLElement)?.focus();
}

type LayoutContextProviderProps = {
  children?: ReactNode;
};

function LayoutContextProvider({ children }: LayoutContextProviderProps) {
  // focus nav on escape
  useEffect(() => {
    document.body.addEventListener("keydown", focusNavOnEscape);
    return () => document.body.removeEventListener("keydown", focusNavOnEscape);
  }, []);

  // keep track of the last focused element
  const lastFocusedElementRef = useRef<HTMLElement | undefined>(undefined);
  const handleFocus = useCallback(() => {
    if (document.activeElement?.classList.contains("nav-target")) return;
    lastFocusedElementRef.current = document.activeElement as HTMLElement;
  }, []);
  useEffect(() => {
    document.addEventListener("focus", handleFocus, true);
    return () => document.removeEventListener("focus", handleFocus, true);
  });

  // restore focus, used by nav links on escape
  const restorePageFocus = useCallback(() => {
    if (
      lastFocusedElementRef.current &&
      lastFocusedElementRef.current.isConnected
    )
      lastFocusedElementRef.current.focus();
    else focusFirstElement();
  }, []);

  const contextValue = useMemo(
    () => ({ restorePageFocus }),
    [restorePageFocus]
  );

  return (
    <LayoutContext.Provider value={contextValue}>
      {children}
    </LayoutContext.Provider>
  );
}

export function useLayoutContext(): LayoutContextValue {
  const value = useContext(LayoutContext);
  if (!value) throw new Error("Layout context provider is missing");
  return value;
}

// layout
// ------

interface LayoutProps {
  children?: ReactNode;
  isFullWidth?: boolean;
  isV1Iframe?: boolean;
}

function Layout({ children, isFullWidth, isV1Iframe }: LayoutProps) {
  const modalManager = useModalContext();
  const showExtensionSetup = useQueryStringValue("showExtensionSetup") === "1";
  const [scrollElement, setScrollElement] = useState<HTMLDivElement | null>(
    null
  );
  const { isOverflowingTop } = useVerticalScrollOverflow(scrollElement);

  useEffect(() => {
    if (showExtensionSetup) {
      modalManager.setActive(ModalName.EXTENSION_INSTALL, {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showExtensionSetup]);

  if (isV1Iframe) {
    return (
      <LayoutContextProvider>
        <div className="h-screen flex">
          <AppNav />
          <div className="w-full">
            <AppHeader isOverflowing={isOverflowingTop} />
            <ErrorBoundary fallback={ErrorPage}>
              <div className="h-[calc(100vh-64px)] overflow-hidden">
                {children}
              </div>
            </ErrorBoundary>
          </div>
        </div>
      </LayoutContextProvider>
    );
  }

  return (
    <LayoutContextProvider>
      <div className="h-screen flex">
        <AppNav />
        <div
          ref={setScrollElement}
          // TODO: workaround for https://github.com/ankeetmaini/react-infinite-scroll-component/issues/59
          id="app-layout-content"
          className="flex-grow bg-white overflow-auto"
        >
          <ExtensionInstallModal />
          <AppHeader isOverflowing={isOverflowingTop} />
          <ErrorBoundary fallback={ErrorPage}>
            <div
              className={clsx("px-6 md:px-8 xl:px-10 mx-auto", {
                "lg:max-w-[64rem] xl:max-w-[72.5rem]": !isFullWidth,
                "w-full": isFullWidth,
              })}
            >
              {children}
            </div>
          </ErrorBoundary>
        </div>
      </div>
    </LayoutContextProvider>
  );
}

export default Layout;
