import {
  SchedulingRequestAssigneeFilter,
  SchedulingRequestPriority,
  SchedulingRequestStatusFilter,
} from "generated/graphql-codegen/graphql";
import { useQueryStringValue } from "utils/next";

export const DEFAULT_ASSIGNEE_FILTER = SchedulingRequestAssigneeFilter.MINE;

export function displayNameForSchedulingRequestPriority(
  priority: SchedulingRequestPriority
) {
  switch (priority) {
    case SchedulingRequestPriority.HIGH:
      return "Urgent";
    case SchedulingRequestPriority.NORMAL:
      return "Normal";
    default:
      return "Unknown";
  }
}

export function displayNameForSchedulingRequestAssigneeFilter(
  filter: SchedulingRequestAssigneeFilter
): string {
  switch (filter) {
    case SchedulingRequestAssigneeFilter.ASSIGNED_TO_USER:
      return "Assigned to me";
    case SchedulingRequestAssigneeFilter.MINE:
      return "Following";
    case SchedulingRequestAssigneeFilter.UNASSIGNED:
      return "Unassigned";
    case SchedulingRequestAssigneeFilter.ALL:
      return "All";
    default:
      return "Unknown Filter";
  }
}

export function descriptionForSchedulingRequestAssigneeFilter(
  filter: SchedulingRequestAssigneeFilter
): string {
  switch (filter) {
    case SchedulingRequestAssigneeFilter.ASSIGNED_TO_USER:
      return "Tasks assigned to you.";
    case SchedulingRequestAssigneeFilter.MINE:
      return "Tasks you created, or where you are the candidate's recruiter.";
    case SchedulingRequestAssigneeFilter.UNASSIGNED:
      return "Tasks currently unassigned.";
    case SchedulingRequestAssigneeFilter.ALL:
      return "All tasks within your organization.";
    default:
      return "Unknown Filter";
  }
}

export function useAssigneeFilterParam() {
  const assigneeFilter = useQueryStringValue("assignee");

  if (
    assigneeFilter === SchedulingRequestAssigneeFilter.ASSIGNED_TO_USER ||
    assigneeFilter ===
      SchedulingRequestAssigneeFilter.ASSIGNED_TO_USER.toLowerCase()
  ) {
    return SchedulingRequestAssigneeFilter.ASSIGNED_TO_USER;
  }

  if (
    assigneeFilter === SchedulingRequestAssigneeFilter.ALL ||
    assigneeFilter === SchedulingRequestAssigneeFilter.ALL.toLowerCase()
  ) {
    return SchedulingRequestAssigneeFilter.ALL;
  }

  if (
    assigneeFilter === SchedulingRequestAssigneeFilter.UNASSIGNED ||
    assigneeFilter === SchedulingRequestAssigneeFilter.UNASSIGNED.toLowerCase()
  ) {
    return SchedulingRequestAssigneeFilter.UNASSIGNED;
  }

  if (
    assigneeFilter === SchedulingRequestAssigneeFilter.MINE ||
    assigneeFilter === SchedulingRequestAssigneeFilter.MINE.toLowerCase()
  ) {
    return SchedulingRequestAssigneeFilter.MINE;
  }

  return DEFAULT_ASSIGNEE_FILTER;
}

export const DEFAULT_STATUS_FILTER = SchedulingRequestStatusFilter.OPEN;
