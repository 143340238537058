import { useToast } from "@resource/atlas/toast/use-toast";
import { useNeedGoogleCalendarPermissions } from "client/hooks/useNeedGoogleCalendarPermissions";
import { RoleEnum } from "enums/role-enum";
import { OrganizationFeaturesEnum } from "generated/graphql-codegen/graphql";
import _ from "lodash";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";
import { PermissionType, StaffRoleEnum } from "shared/auth/permissions";
import inIframe from "utils/in-iframe";
import { GetLayout, withConditionalLayout } from "utils/next-layout";

import { useAuthContext } from "./context";

export interface PageAuthOptions {
  requiredPermissions?:
    | PermissionType[]
    | PermissionType
    | (() => PermissionType | PermissionType[] | undefined);
  requiredFeatures?: OrganizationFeaturesEnum | OrganizationFeaturesEnum[];
  staffRoleRequired?: StaffRoleEnum;
  redirectPath?: string;
  getLayout?: GetLayout;
}

/**
 * An authentication wrapper that allows passing in a layout function so that
 * we can maintain the tree across routes.
 */
const withPageAuthRequired = <T extends () => JSX.Element | null>(
  page: T,
  options?: PageAuthOptions
) => {
  const {
    staffRoleRequired,
    requiredPermissions,
    getLayout = _.identity,
    requiredFeatures,
  } = options ?? {};
  const inExtension = inIframe();

  return withConditionalLayout(getLayout, () => {
    const router = useRouter();
    const path = router.pathname;
    const authContext = useAuthContext();
    const { sendToast } = useToast();

    const { loading: loadingGcal, hasPermissions: hasGcalPermissions } =
      useNeedGoogleCalendarPermissions();

    useEffect(() => {
      const listener = (ev: MessageEvent) => {
        if (typeof ev.data === "object") {
          if (ev.data.command === "toast") {
            sendToast(ev.data.message, ev.data.options);
          }
        }
      };
      window.addEventListener("message", listener);
      return () => window.removeEventListener("message", listener);
    }, [sendToast]);

    const redirectPath = useMemo(() => {
      const {
        user: currentUser,
        highestRole,
        loading,
        checkStaffRole,
        checkRolePermissions,
        onboardingComplete,
        error,
      } = authContext;

      if (error) return "/_error";

      if (loading) {
        return undefined;
      }

      // User is unauthenticated
      if (!currentUser) {
        const returnToOrArr = router.query.returnTo || router.asPath;
        const returnTo = Array.isArray(returnToOrArr)
          ? returnToOrArr[0]
          : returnToOrArr;
        return `/login?returnTo=${encodeURIComponent(returnTo)}`;
      }

      // Check Staff Permissions
      if (staffRoleRequired && !checkStaffRole(staffRoleRequired)) {
        return "/invalid-permissions";
      }

      if (highestRole === RoleEnum.Candidate) {
        return "/candidate-redirect";
      }

      // Check User Permissions
      const perms =
        typeof requiredPermissions === "function"
          ? requiredPermissions()
          : requiredPermissions;

      if (!onboardingComplete && !inExtension) {
        if (path.startsWith("/new-user")) {
          return undefined;
        }
        return "/new-user";
      }

      const rolesRequiringGoogleCalendarPermissions = [
        RoleEnum.Admin,
        RoleEnum.Manager,
        RoleEnum.Member,
      ];

      if (
        !loadingGcal &&
        !hasGcalPermissions &&
        authContext.user?.currentUserMembership?.highestRole &&
        rolesRequiringGoogleCalendarPermissions.includes(
          authContext.user.currentUserMembership.highestRole.id as RoleEnum
        )
      ) {
        return `/google-calendar-permissions?returnTo=${encodeURIComponent(
          path
        )}`;
      }

      if (perms && !checkRolePermissions(perms)) {
        return "/invalid-permissions";
      }

      if (requiredFeatures) {
        if (!authContext.hasFeatureEnabled(requiredFeatures)) {
          return "/invalid-permissions";
        }
      }

      return undefined;
    }, [
      authContext,
      hasGcalPermissions,
      loadingGcal,
      path,
      router.asPath,
      router.query.returnTo,
    ]);

    if (redirectPath) {
      router.replace(redirectPath);
    }

    return !authContext.loading && !redirectPath;
  })(page);
};

export default withPageAuthRequired;
