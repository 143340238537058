/* eslint-disable jsx-a11y/anchor-has-content */
import { Button } from "@resource/atlas/button/Button";
import { atlasRingQuestion } from "@resource/atlas/icons";
import { Menu } from "@resource/atlas/menu";
import type { AtlasMenuContentItem } from "@resource/atlas/menu/types";
import { useLogEvent } from "analytics";
import { useMemo } from "react";
import { V2_HELP_CENTER_PAGE } from "utils/constants/href";
import useIntercom from "utils/useIntercom";

export function HelpMenu() {
  const { callIntercom } = useIntercom();
  const logEvent = useLogEvent({ component: "HelpMenu" });

  const items: AtlasMenuContentItem[] = useMemo(
    () => [
      {
        _type: "item",
        children: "Contact support",
        key: "contact-support",
        onSelect: () => logEvent("Help Menu Contact Support Clicked"),
        renderWrapper: (props) => (
          <button
            className="w-full text-left block"
            type="button"
            {...props}
            onClick={() => callIntercom("show")}
            onKeyUp={() => callIntercom("show")}
          />
        ),
      },
      {
        _type: "item",
        children: "Help center",
        key: "help-center",
        onSelect: () => logEvent("Account Menu Help Center Clicked"),
        renderWrapper: (props) => (
          <a
            {...props}
            href={V2_HELP_CENTER_PAGE}
            target="_blank"
            rel="noreferrer"
          />
        ),
      },
    ],
    [callIntercom, logEvent]
  );

  return (
    <Menu.Root>
      <Menu.Trigger>
        <Button icon={atlasRingQuestion} isGhost aria-label="Open help menu" />
      </Menu.Trigger>
      <Menu.Content defaultSize="compact" items={items} />
    </Menu.Root>
  );
}
